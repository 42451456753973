import MainHeading from '@/components/HeroSection/MainHeading'
import React, { useEffect } from 'react'
import LessonProgressCard from '../LessonProgressCard'

type AllProgressPageProps = {}

const AllProgressPage: React.FC<AllProgressPageProps> = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const lessonData = [
        {
            subjectName: 'Core Maths',
            topic: 'Trigonometry',
            lessonsLeft: 33,
            continueLink: '#',
            progress: '20%',
        },
        {
            subjectName: 'Biology',
            topic: 'Cell Structure and Function',
            lessonsLeft: 12,
            continueLink: '#',
            progress: '45%',
        },
        {
            subjectName: 'Physics',
            topic: 'Electromagnetism',
            lessonsLeft: 9,
            continueLink: '#',
            progress: '60%',
        },
        {
            subjectName: 'English Language',
            topic: 'Creative Writing',
            lessonsLeft: 5,
            continueLink: '#',
            progress: '80%',
        },
        {
            subjectName: 'History',
            topic: 'World War II',
            lessonsLeft: 15,
            continueLink: '#',
            progress: '30%',
        },
        {
            subjectName: 'Chemistry',
            topic: 'Organic Compounds',
            lessonsLeft: 18,
            continueLink: '#',
            progress: '25%',
        },
        {
            subjectName: 'Geography',
            topic: 'Climatic Regions',
            lessonsLeft: 8,
            continueLink: '#',
            progress: '50%',
        },
    ]
    return (
        <>
            <section className="mx-5 md:mx-10 my-8">
                <div className="my-5">
                    <MainHeading
                        text="Progress Overview"
                        className=" text-xl font-semibold text-bodyText "
                    />
                </div>
                <div className="">
                    {lessonData.map((lesson, index) => (
                        <LessonProgressCard
                            key={index}
                            subjectName={lesson.subjectName}
                            topic={lesson.topic}
                            lessonsLeft={lesson.lessonsLeft}
                            continueLink={lesson.continueLink}
                            progress={lesson.progress}
                            className={'flex xl:w-4/5 w-full'}
                        />
                    ))}
                </div>
            </section>
        </>
    )
}

export default AllProgressPage
