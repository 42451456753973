import { Icon } from '@iconify/react'
import React from 'react'

interface TopicItemProps {
    title: string
    duration: string
    isSelected: boolean
    onClick: () => void
}

const TopicItem: React.FC<TopicItemProps> = ({
    title,
    duration,
    isSelected,
    onClick,
}) => {
    return (
        <div
            className={`p-4 cursor-pointer flex items-center justify-between ${isSelected ? 'bg-blue-100' : 'bg-white'} rounded-lg mb-2 hover:bg-blue-50`}
            onClick={onClick}
        >
            <div className=" flex justify-between items-center w-full">
                <div>
                    <h3 className="text-sm font-lato">{title}</h3>
                    <span className="text-sm text-gray-500">{duration}</span>
                </div>

                <Icon icon="hugeicons:play-square" className="text-2xl" />
            </div>
        </div>
    )
}

export default TopicItem
