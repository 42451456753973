import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../components/Header/Logo'
import MainHeading from '../../components/HeroSection/MainHeading'
import google_icon from '../../assets/Social_google.png'
import LoginForm from '../../components/LoginForm'
import LoadingModal from '../../components/LoadingModal'
import projectLogo from '../../assets/project_logo.png'
import { RouteLinks } from '../../constants/RouteLinks'
import { useNavigate } from 'react-router-dom'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth, googleProvider } from 'src/firebaseConfig'
import { useAuth } from 'src/context/AuthContext'

const Login: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const { setRole } = useAuth()

    const validateInputs = () => {
        if (!email) {
            setErrorMessage('Email is required.')
            return false
        }
        if (!password) {
            setErrorMessage('Password is required.')
            return false
        }
        return true
    }
    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault()
        setErrorMessage('')

        if (!validateInputs()) {
            return
        }

        setIsLoading(true)

        try {
            const response = await fetch(
                'https://us-central1-project-x-backend-92650.cloudfunctions.net/api/auth/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                }
            )

            const data = await response.json()
            console.log('data from login', data)

            if (response.ok && data.token) {
                console.log('Ready to redirect')
                // Save token to localStorage
                localStorage.setItem('authToken', data.token)
                localStorage.setItem('userRole', data.user.role)

                setRole(data.user.role)
                // Redirect to dashboard based on role
                setTimeout(() => {
                    if (data.user.role === 'student') {
                        console.log('Ready to redirect')
                        navigate(RouteLinks.STUDENT_DASHBOARD)
                    } else if (data.user.role === 'parent') {
                        navigate(RouteLinks.PARENT_DASHBOARD)
                    }
                }, 500)
            } else {
                // Handle error responses
                const error =
                    data.error || 'An error occurred. Please try again.'
                setErrorMessage(
                    response.status === 401
                        ? 'Incorrect email or password.'
                        : error
                )
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage('A network error occurred. Please try again later.')
        } finally {
            setIsLoading(false)
        }
    }

    const signInWithGoogle = async () => {
        setErrorMessage('')
        setIsLoading(true)

        try {
            const response = await signInWithPopup(auth, googleProvider)
            console.log('Response from loging', response)

            if (response.user) {
                navigate(RouteLinks.DASHBOARD)
            } else {
                setErrorMessage('Google sign-in failed. Please try again.')
            }
        } catch (error) {
            console.error('Google Sign-In Error:', error)
            setErrorMessage('An error occurred. Please try again later.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <section>
            <div className="mx-auto max-w-[1032px] max-h-screen mb-10">
                <div className="md:flex justify-between items-center w-full md:my-8 md:px-20 xl:px-0 hidden">
                    <Logo imgUrl={projectLogo} className="h-9 w-[133.6px]" />
                    <div className="hidden md:flex justify-end mb-4 p-4">
                        <Link
                            to={RouteLinks.SIGN_UP}
                            className="text-sm text-gray-600"
                        >
                            Don’t have an account yet? 
                            <span className="text-[#24983F] underline">
                                Sign up
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="w-full block md:hidden bg-[#E6F2FF66] p-3">
                    <Logo imgUrl={projectLogo} className="h-11 w-44" />
                </div>
                <MainHeading
                    text="Welcome back!"
                    className="text-center font-medium text-[28px] my-10 md:mt-[0]"
                />

                <div className="flex mx-auto max-w-[460px] md:mt-10 w-11/12 sm:w-[460px]">
                    <button
                        onClick={signInWithGoogle}
                        className="flex justify-center items-center w-full h-14 rounded-full border-2 border-gray-100 hover:bg-gray-100"
                    >
                        <img
                            src={google_icon}
                            className="w-5 h-5 mx-2 text-bodyText text-lg"
                            alt="google button"
                        />
                        Sign In with Google
                    </button>
                </div>

                <div className="flex max-w-[450px] w-full sm:w-[500px] space-x-1 items-center mx-auto my-8">
                    <strong className="h-0.5 w-full bg-[#f1f1f1]"></strong>
                    <span className="text-bodyText">or</span>
                    <strong className="h-0.5 w-full bg-[#f1f1f1]"></strong>
                </div>
                <div className="flex justify-center mx-auto xl:w-3/5 h-auto px-5">
                    <LoginForm
                        email={email}
                        password={password}
                        setEmail={setEmail}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        passwordError={passwordError}
                        emailError={emailError}
                        errorMessage={errorMessage}
                    />
                </div>
                <div className="flex md:hidden justify-center my-5">
                    <Link
                        to={RouteLinks.SIGN_UP}
                        className="text-sm text-gray-600"
                    >
                        Don’t have an account yet?{' '}
                        <span className="text-[#24983F] underline">
                            Sign Up
                        </span>
                    </Link>
                </div>

                {isLoading && <LoadingModal alert="Logging In!" />}
            </div>
        </section>
    )
}

export default Login
