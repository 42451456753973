import Swiper from '../../Swiper/SwiperComponent'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Link } from 'react-router-dom'
import trendingUp from '../../../assets/svg/trending-up.svg'
import star from '../../../assets/svg/star.svg'
import SwiperComponent from '../../Swiper/SwiperComponent'
import SliderComponent from '@/components/Slider'
import { RouteLinks } from '@/constants/RouteLinks'

interface Subject {
    id: number
    name: string
    topics: number
    lessons: number
    hours: number
}
type HomeProps = {}

const Home: React.FC<HomeProps> = () => {
    const { isOpen } = useOutletContext<{ isOpen: boolean }>()
    const subjects: Subject[] = [
        {
            id: 1,
            name: 'Core Mathematics',
            topics: 44,
            lessons: 323,
            hours: 80,
        },
        { id: 2, name: 'Biology', topics: 44, lessons: 323, hours: 80 },
        {
            id: 3,
            name: 'Integrated Science',
            topics: 44,
            lessons: 323,
            hours: 80,
        },
        { id: 4, name: 'French', topics: 44, lessons: 323, hours: 80 },
        { id: 5, name: 'I.C.T', topics: 44, lessons: 323, hours: 80 },
    ]
    return (
        <section
            className={` overflow-x-hidden w-[100vw] ${isOpen ? 'w-[80vw]  ' : ' w-[100vw] lg:w-[90vw]'}`}
        >
            <div className="flex w-full">
                <div className={` transition-all duration-300 w-full`}>
                    <section className="relative m-5 xl:m-10">
                        <div className="lg:hidden px-2 py-8 border-b border-t w-full">
                            <div className="bg-[#FAFAFA] p-2 rounded-md">
                                <h1 className="text-[16px] font-bold font-lato ">
                                    Hey, Welcome back!👋
                                </h1>
                                <h2 className="font-lato text-sm">
                                    Peterkin Yanzu
                                </h2>
                            </div>
                        </div>
                        <div className="block p-3 w-full lg:bg-[#FAFAFA] text-subText">
                            <h1 className="text-xl lg:text-[25px] font-medium">
                                Let's Get Started!
                            </h1>
                            <h2 className="font-lato text-sm lg:text-lg">
                                Based on your preferences, we’ve personalized
                                your learning journey.
                            </h2>
                        </div>

                        {/* Carousel */}
                        {/* <div className="w-[880px]">
                            <Swiper subjects={subjects} isOpen={isOpen} />
                        </div> */}
                        <div className="w-full lg:ml-[30px]">
                            <SliderComponent
                                subjects={subjects}
                                isOpen={isOpen}
                            />
                        </div>
                    </section>

                    <section className="my-10 mx-5 xl:m-10 xl:flex">
                        <div className="xl:w-4/5 w-full xl:border-r border-gray-200">
                            <div className="my-10">
                                <h1 className=" text-xl font-semibold my-3 text-bodyText">
                                    Progress Overview
                                </h1>
                                <div className="border rounded-md text-center space-y-5 w-full xl:w-[90%] p-4">
                                    <div className="p-2 bg-gray-100 rounded-full h-[60px] w-[60px] mx-auto">
                                        <img src={trendingUp} className="" />
                                    </div>
                                    <h1 className="text-lg font-lato text-subText font-semibold">
                                        Start your first lesson to track your
                                        progress!
                                    </h1>
                                    <button>
                                        <Link
                                            to={'#'}
                                            className="w-[131px] h-10 text-sm font-lato p-2 py-3 bg-primaryBlue text-white rounded-full"
                                        >
                                            Explore Lessons
                                        </Link>
                                    </button>
                                </div>
                            </div>
                            <div className="my-10">
                                <h1 className=" text-xl font-semibold my-3 text-bodyText">
                                    Leaderboard Highlights
                                </h1>
                                <div className="border rounded-md text-center space-y-5  w-full xl:w-[90%] p-4">
                                    <div className="flex justify-center items-center p-2 bg-gray-100 rounded-full h-[60px] w-[60px] mx-auto">
                                        <img src={star} className="mx-auto" />
                                    </div>
                                    <h1 className="text-lg font-lato text-subText font-semibold">
                                        Complete lessons to earn points and
                                        appear on the leaderboard
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="lg:flex gap-5 xl:block">
                            <div className="w-full lg:w-2/3 mx-auto my-10">
                                <h1 className=" text-xl font-semibold my-3 text-bodyText">
                                    Upcoming Tasks
                                </h1>
                                <div className="border rounded-lg lg:w-11/12 xl:w-auto">
                                    <div className="flex my-4 mx-2 p-2 rounded-md bg-gray-100">
                                        <i className="bx bx-calendar text-2xl"></i>
                                        <p className="text-lg font-lato">
                                            No tasks yet, but they’ll show up
                                            soon once you start learning!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-2/3 mx-auto my-10 xl:my-16 lg:pl-5 xl:pt-5 xl:pl-0  lg:border-l lg:border-t-0 xl:border-l-0 border-t ">
                                <h1 className=" text-xl font-semibold my-5 lg:my-1 text-bodyText">
                                    Quick Links
                                </h1>
                                <div className="lg:flex justify-between w-full lg:w-3/4 xl:w-full">
                                    <Link
                                        to={RouteLinks.DASHBOARD_ACHIEVEMENTS}
                                        className="flex items-center my-4 mx-2 p-2 rounded-md border"
                                    >
                                        <i className="bx bx-trophy text-2xl"></i>
                                        <p className="text-sm font-lato">
                                            Achievements
                                        </p>
                                    </Link>
                                    <Link
                                        to={''}
                                        className="flex items-center my-4 mx-2 p-2 rounded-md border"
                                    >
                                        <i className="bx bx-file text-2xl"></i>
                                        <p className="text-sm font-lato">
                                            Resources
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default Home
