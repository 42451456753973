import Accordion from '@/components/Accordion'
import CTAButton from '@/components/HeroSection/CTAButton'
import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import PopupMenu from '../PopupMenu'

import { Link } from 'react-router-dom'
import { RouteLinks } from '@/constants/RouteLinks'

type NewSubjectProps = {}

const NewSubject: React.FC<NewSubjectProps> = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isExistingUser, setIsExistingUser] = useState(false)

    const handleModalOpen = () => {
        setIsModalOpen(true)
    }
    const lessonData = [
        {
            subjectName: 'Core Maths',
            topic: 'Trigonometry',
            lessonsLeft: 33,
            continueLink: '#',
            progress: '20%',
        },
        {
            subjectName: 'Biology',
            topic: 'Cell Structure and Function',
            lessonsLeft: 12,
            continueLink: '#',
            progress: '45%',
        },
        {
            subjectName: 'Physics',
            topic: 'Electromagnetism',
            lessonsLeft: 9,
            continueLink: '#',
            progress: '60%',
        },
    ]
    const topicsData = [
        {
            id: 1,
            title: 'Expressions and Equations',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
        {
            id: 2,
            title: 'Inequalities',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Introduction to Inequalities', duration: '10min' },
            ],
        },
        {
            id: 3,
            title: 'Functions and Graphs',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [{ title: 'Understanding Graphs', duration: '8min' }],
        },
        {
            id: 4,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
        {
            id: 5,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
        {
            id: 6,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
        {
            id: 7,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
        {
            id: 8,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [{ title: 'Profit and Loss', duration: '5min' }],
        },
        {
            id: 9,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
        {
            id: 10,
            title: 'Business Mathematics',
            lessonsCount: 16,
            videosCount: 12,
            lessons: [
                { title: 'Simplifying Expressions', duration: '6min' },
                { title: 'Definition', duration: '2min' },
                { title: 'Evaluating Expressions', duration: '4min' },
                { title: 'Evaluating Expressions', duration: '32min' },
                { title: 'Evaluating Expressions', duration: '40min' },
            ],
        },
    ]
    return (
        <section className="m-5 xl:mx-10">
            <div className=" ">
                <div className="w-full md:w-4/5 xl:w-3/5">
                    <h1 className="hidden lg:block text-[32px] font-medium text-bodyText my-1">
                        Algebraic Expression
                    </h1>
                    <div className="lg:hidden py-5 border-b ">
                        <h1 className=" text-lg font-semibold">Algebra</h1>
                        <p className=" text-sm font-lato text-gray-400">
                            Courses / Core Mathematics /{' '}
                            <span className=" text-gray-600 font-semibold">
                                Algebra
                            </span>
                        </p>
                    </div>
                    <button
                        className="lg:hidden mx-auto px-5 rounded-full shadow-md border text-xs font-medium font-lato my-5 w-full py-3"
                        onClick={handleModalOpen}
                    >
                        View Topic Details
                    </button>
                    <p className="hidden lg:block text-base font-lato text-bodyText">
                        By completing this topic, you’ll master essential
                        algebraic concepts, enhance your logical reasoning, and
                        develop problem-solving skills hat form the foundation
                        for advanced mathematics.
                    </p>
                </div>
                <div className="lg:flex my-5 lg:my-10">
                    <div className="lg:w-3/5 ">
                        <Accordion topics={topicsData} />
                    </div>
                    <div className="lg:hidden border-t border-gray-300 mt-10">
                        <CTAButton
                            label="Start Learning"
                            linkUrl={''}
                            className="px-3 rounded-full bg-primaryBlue text-white text-base font-lato font-semibold mt-5"
                        />
                    </div>

                    <div className="hidden lg:block mx-10 px-5 border-l h-fit md:w-2/5">
                        {isExistingUser && (
                            <div>
                                <h1 className="font-bold text-lg font-lato">
                                    Keep going! Continue your course from the
                                    last session
                                </h1>
                                {lessonData.map((lesson, index) => (
                                    <div
                                        key={index}
                                        className="items-center border rounded-xl md:rounded-lg p-2 my-5 shadow-lg lg:shadow-[0px]"
                                    >
                                        <div className="xl:w-4/5 w-[90%] font-lato space-y-2">
                                            <h2 className="text-lg font-semibold">
                                                {lesson.topic}
                                            </h2>
                                            <h1 className="text-[#969696]">
                                                {lesson.subjectName}
                                            </h1>
                                            <div className="relative flex justify-between transition-all duration-300 items-center rounded-full h-[6px] bg-gray-200 w-[95%]">
                                                <div
                                                    className="transition-all duration-300 h-[6px] bg-ctaGreen rounded-full"
                                                    style={{
                                                        width: `${lesson.progress}`,
                                                    }}
                                                ></div>
                                                <div className="absolute -right-10 flex text-sm">
                                                    <span className="mx-1">
                                                        {lesson.progress}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="my-5">
                                            <Link
                                                to={''}
                                                className="bg-primaryBlue text-white rounded-full px-3 py-2"
                                            >
                                                Continue
                                            </Link>
                                        </button>
                                    </div>
                                ))}
                                <button className="flex w-full mx-auto my-10">
                                    <Link
                                        to={''}
                                        className="border border-primaryBlue bg-white text-primaryBlue rounded-full px-8 py-1 w-full"
                                    >
                                        View All
                                    </Link>
                                </button>
                            </div>
                        )}
                        <h1 className=" font-bold text-lg font-lato">
                            Topic Details
                        </h1>
                        <div className="p-3 rounded-lg border my-3">
                            <div className=" flex items-center space-x-2 my-3">
                                <Icon icon="ion:calendar-outline" />
                                <h2 className=" text-sm font-semibold font-lato">
                                    6 weeks
                                </h2>
                            </div>
                            <div className=" flex items-center space-x-2 my-3">
                                <Icon icon="mynaui:chart-bar" />
                                <h2 className=" text-sm font-semibold font-lato">
                                    Beginner to Advance
                                </h2>
                            </div>
                            <div className=" flex items-center space-x-2 my-3">
                                <Icon icon="lets-icons:clock" />
                                <h2 className=" text-sm font-semibold font-lato">
                                    20-30 hours
                                </h2>
                            </div>
                            <div className=" flex items-center space-x-2 my-3">
                                <Icon icon="icon-park-outline:book" />
                                <h2 className=" text-sm font-semibold font-lato">
                                    6 Comprehensive Modules
                                </h2>
                            </div>
                            <div className=" flex items-center space-x-2 my-3">
                                <Icon icon="streamline:module-puzzle-1" />
                                <h2 className=" text-sm font-semibold font-lato">
                                    Interactive Lessons, Quizzes, PDFs
                                </h2>
                            </div>
                            <div className=" flex items-center space-x-2 my-3">
                                <Icon icon="mynaui:download" />
                                <h2 className=" text-sm font-semibold font-lato">
                                    189 Downloadable Resources
                                </h2>
                            </div>
                        </div>
                        <CTAButton
                            label={
                                isExistingUser
                                    ? 'View Last Session'
                                    : 'Start Learning'
                            }
                            linkUrl={RouteLinks.DASHBOARD_LEARNINGSPACE}
                            className="px-3 rounded-full bg-primaryBlue text-white text-base font-lato font-semibold my-10"
                        />
                    </div>
                </div>
            </div>
            {/* Conditionally render modal */}
            {isModalOpen && <PopupMenu setIsModalOpen={setIsModalOpen} />}
        </section>
    )
}

export default NewSubject
