import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Dashboard from './Dashboard'
import ParentDashboard from './ParentDashboard'
import { RouteLinks } from '@/constants/RouteLinks'

const RoleBasedDashboard: React.FC = () => {
    const { role } = useAuth()

    if (role === 'student') {
        return <Dashboard />
    } else if (role === 'parent') {
        return <ParentDashboard />
    }

    // Handle the case where the role is not set or invalid
    return <Navigate to={RouteLinks.LOGIN} replace />
}

export default RoleBasedDashboard
