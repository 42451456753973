import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { RouteLinks } from '@/constants/RouteLinks'
import LoadingModal from './LoadingModal'

interface ProtectedRouteProps {
    children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { user, role, isLoading } = useAuth()
    const location = useLocation()

    // Show a loading state while authentication is being resolved
    if (isLoading) {
        return <LoadingModal alert="Loading" />
    }

    // Redirect to login if the user is not authenticated
    if (!user) {
        return <Navigate to={RouteLinks.LOGIN} replace />
    }

    // Check user role and redirect only if they are on an incorrect dashboard
    if (
        role === 'student' &&
        location.pathname.startsWith(RouteLinks.PARENT_DASHBOARD)
    ) {
        return <Navigate to={RouteLinks.STUDENT_DASHBOARD} replace />
    }

    if (
        role === 'parent' &&
        location.pathname.startsWith(RouteLinks.STUDENT_DASHBOARD)
    ) {
        return <Navigate to={RouteLinks.PARENT_DASHBOARD} replace />
    }

    // Render the children if everything checks out
    return <>{children}</>
}

export default ProtectedRoute
