import React from 'react'
import TopicItem from '../TopicItem'

interface LessonPanelProps {
    topics: { title: string; duration: string; status: string }[]
    selectedTopic: number
    onSelectTopic: (index: number) => void
}

const LessonPanel: React.FC<LessonPanelProps> = ({
    topics,
    selectedTopic,
    onSelectTopic,
}) => {
    return (
        <div className="w-1/4 p-4 bg-gray-100 rounded-lg shadow-md">
            {topics.map((topic, index) => (
                <TopicItem
                    key={index}
                    title={topic.title}
                    duration={topic.duration}
                    isSelected={index === selectedTopic}
                    onClick={() => onSelectTopic(index)}
                />
            ))}
        </div>
    )
}

export default LessonPanel
