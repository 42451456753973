import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth } from '../firebaseConfig' // Import Firebase configuration
import { User, onAuthStateChanged, signOut } from 'firebase/auth'
import { RouteLinks } from '@/constants/RouteLinks'

// Define user roles and context type
type UserRole = 'student' | 'parent' | null

type AuthContextType = {
    user: User | null
    role: UserRole
    setRole: (role: UserRole) => void
    emailVerified: boolean
    isLoading: boolean
    logout: () => Promise<void>
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined)

// AuthProvider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [user, setUser] = useState<User | null>(null)
    const [role, setRole] = useState<UserRole>(null)
    const [emailVerified, setEmailVerified] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setIsLoading(true)
            if (currentUser) {
                setUser(currentUser)
                setEmailVerified(!!currentUser.emailVerified)

                // Get the role directly from localStorage
                const storedRole = localStorage.getItem('userRole')
                if (storedRole === 'student' || storedRole === 'parent') {
                    setRole(storedRole as UserRole)
                } else {
                    setRole(null) // If no role is found, reset to null
                }
            } else {
                setUser(null)
                setRole(null)
                setEmailVerified(false)
                localStorage.removeItem('userRole') // Clear cached role
                localStorage.removeItem('authToken') // Clear token
            }
            setIsLoading(false)
        })

        return () => unsubscribe() // Clean up the listener when component unmounts
    }, [])

    // Logout function
    const logout = async () => {
        setIsLoading(true)
        try {
            await signOut(auth)
            localStorage.removeItem('authToken')
            localStorage.removeItem('userRole')
            setUser(null)
            setRole(null)
            window.location.href = RouteLinks.LOGIN
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <AuthContext.Provider
            value={{ user, role, emailVerified, isLoading, setRole, logout }}
        >
            {!isLoading && children}{' '}
            {/* Render the children components when loading is complete */}
        </AuthContext.Provider>
    )
}

// Hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
