import { Icon } from '@iconify/react'
import React from 'react'

type PopupMenuProps = {
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PopupMenu: React.FC<PopupMenuProps> = ({ setIsModalOpen }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity z-10">
            <div className="bg-white p-5 rounded-lg shadow-lg relative w-[90%] max-w-md">
                <div className=" flex justify-between items-center">
                    <h1 className=" font-lato font-bold">Topic Details</h1>
                    <Icon
                        icon="gridicons:cross"
                        className=" text-2xl "
                        onClick={() => setIsModalOpen(false)}
                    />
                </div>
                <div className="p-3 rounded-lg border my-3">
                    <div className=" flex items-center space-x-2 my-3">
                        <Icon icon="ion:calendar-outline" />
                        <h2 className=" text-sm font-semibold font-lato">
                            6 weeks
                        </h2>
                    </div>
                    <div className=" flex items-center space-x-2 my-3">
                        <Icon icon="mynaui:chart-bar" />
                        <h2 className=" text-sm font-semibold font-lato">
                            Beginner to Advance
                        </h2>
                    </div>
                    <div className=" flex items-center space-x-2 my-3">
                        <Icon icon="lets-icons:clock" />
                        <h2 className=" text-sm font-semibold font-lato">
                            20-30 hours
                        </h2>
                    </div>
                    <div className=" flex items-center space-x-2 my-3">
                        <Icon icon="icon-park-outline:book" />
                        <h2 className=" text-sm font-semibold font-lato">
                            6 Comprehensive Modules
                        </h2>
                    </div>
                    <div className=" flex items-center space-x-2 my-3">
                        <Icon icon="streamline:module-puzzle-1" />
                        <h2 className=" text-sm font-semibold font-lato">
                            Interactive Lessons, Quizzes, PDFs
                        </h2>
                    </div>
                    <div className=" flex items-center space-x-2 my-3">
                        <Icon icon="mynaui:download" />
                        <h2 className=" text-sm font-semibold font-lato">
                            189 Downloadable Resources
                        </h2>
                    </div>
                </div>

                {/* Skip for now Button */}
            </div>
        </div>
    )
}

export default PopupMenu
