import React, { useState } from 'react'
import VideoPlayer from '../VideoPlayer'
import LessonPanel from '../LessonPanel'

const topics = [
    {
        title: 'Completing the square',
        duration: '2min',
        videoUrl: 'https://www.example.com/video1.mp4',
        status: 'completed',
    },
    {
        title: 'Graphs of Quadratic Equations',
        duration: '2min',
        videoUrl: 'https://www.example.com/video2.mp4',
        status: 'completed',
    },
    {
        title: 'Solving quadratic equations by factoring',
        duration: '2min',
        videoUrl: 'https://www.example.com/video3.mp4',
        status: 'completed',
    },
    {
        title: 'Quadratic Formula',
        duration: '2min',
        videoUrl: 'https://www.example.com/video4.mp4',
        status: 'completed',
    },
]

const LearningSpace: React.FC = () => {
    const [selectedTopic, setSelectedTopic] = useState(0)
    const [isExpanded, setIsExpanded] = useState(true)
    // Get the details of the currently selected topic
    const currentTopic = topics[selectedTopic]

    return (
        <div className="flex w-full h-screen p-8">
            {/* Lesson Panel */}
            <LessonPanel
                topics={topics}
                selectedTopic={selectedTopic}
                onSelectTopic={setSelectedTopic}
            />

            {/* Video Player Area */}
            <div className="w-3/4 pl-8">
                <VideoPlayer
                    videoUrl={currentTopic.videoUrl}
                    duration="11:15"
                    title={topics[selectedTopic].title}
                />
            </div>
        </div>
    )
}

export default LearningSpace
