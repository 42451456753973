import React from 'react'

interface VideoPlayerProps {
    videoUrl: string
    duration: string
    title: string
}

// Helper function to determine if the URL is a YouTube link
const isYouTubeUrl = (url: string) => {
    return url.includes('youtube.com') || url.includes('youtu.be')
}

// Extract the YouTube video ID from a YouTube URL
const getYouTubeEmbedUrl = (url: string) => {
    const videoIdMatch = url.match(
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    )
    return videoIdMatch
        ? `https://www.youtube.com/embed/${videoIdMatch[1]}`
        : url
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    videoUrl,
    duration,
    title,
}) => {
    return (
        <div className="w-full">
            {/* Render iframe for YouTube links, otherwise render video tag */}
            {isYouTubeUrl(videoUrl) ? (
                <iframe
                    src={getYouTubeEmbedUrl(videoUrl)}
                    title={title}
                    className="w-full h-[400px] rounded-lg shadow-lg"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            ) : (
                <video
                    src={videoUrl}
                    controls
                    className="w-full h-[400px] rounded-lg shadow-lg"
                />
            )}
            <div className="flex justify-between mt-4">
                <h2 className="text-lg font-semibold">{title}</h2>
                <span className="text-gray-500">{duration}</span>
            </div>
            <div className="mt-4 text-blue-600">
                <a href="#" className="text-sm">
                    Submit rating
                </a>
            </div>
            <div className="mt-4">
                <button className="text-blue-600 text-sm">
                    Frequently Asked Questions
                </button>
            </div>
        </div>
    )
}

export default VideoPlayer
